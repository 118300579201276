<template>
    <div>
        <p class="mt-4 mx-2">
            Suivez et gérez ici les différentes ventes éligibles à l'extranet client
        </p>

        <v-card class="pb-2">
            <v-card-title class="d-flex align-center pa-2">
                <v-text-field v-model="search.quickSearch" @keyup.enter="fetchSales()" class="flex-grow-0 mr-2" label="Recherche" color="app-blue" prepend-inner-icon="fas fa-search" outlined hide-details dense />

                <ProgramAutocomplete v-model="search.programs" @input="fetchSales()" ref="programAutocomplete" label="Programmes" class="mr-2" flexGrow0 noDynamicSearch multiple />

                <v-btn @click="displayAdvanceSearch = !displayAdvanceSearch" color="app-blue" class="mr-2" outlined depressed>
                    <v-icon v-if="!displayAdvanceSearch" left x-small> fas fa-plus </v-icon>
                    <v-icon v-if="displayAdvanceSearch" left x-small> fas fa-minus </v-icon>
                    de filtres
                </v-btn>

                <CreateAppointmentDialog v-if="false">
                    <template v-slot:activator="{ on }">
                        <v-btn color="app-blue" outlined depressed v-on="on">
                            <v-icon left x-small> fas fa-plus </v-icon>
                            Rendez-vous
                        </v-btn>
                    </template>
                </CreateAppointmentDialog>

                <v-spacer />

                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-chip label v-on="on"> {{ salesTotalCount }} </v-chip>
                    </template>
                    Nombre total de réservations
                </v-tooltip>

                <v-pagination v-model="page" @input="fetchSales(true, false)" :length="salesTotalPages" total-visible="5" color="app-blue" />
            </v-card-title>

            <!-- Advance search -->
            <v-expand-transition>
                <v-sheet v-show="displayAdvanceSearch" color="grey lighten-3">
                    <div class="pa-4">
                        <div class="d-flex justify-space-between">
                            <table>
                                <tr>
                                    <td class="pl-4">
                                        <DatePickerMenu v-model="search.contractDate" @input="fetchSales()" label="Date du contrat" range />
                                    </td>
                                </tr>

                                <tr>
                                    <td class="pl-4 pt-4">
                                        <DatePickerMenu v-model="search.sruSentDate" @input="fetchSales()" label="Date de fin SRU" range />
                                    </td>
                                </tr>
                            </table>

                            <v-divider vertical />

                            <table>
                                <tr>
                                    <td>
                                        <!-- <span class="white-space-no-wrap">
                                            Espace client ouvert
                                        </span> -->
                                    </td>

                                    <td class="pl-4">
                                        <div class="d-flex align-center justify-end">
                                            <!-- <v-btn-toggle v-model="search.extranetOpen" @change="fetchSales()" class="mr-4" color="app-blue" dense>
                                                <v-btn value="1">
                                                    Oui
                                                </v-btn>

                                                <v-btn value="0">
                                                    Non
                                                </v-btn>
                                            </v-btn-toggle> -->

                                            <div>
                                                <DatePickerMenu v-model="search.extranetOpenDate" @input="fetchSales()" label="Ouverture extranet" range />
                                            </div>
                                        </div>
                                    </td>
                                </tr>

                                <tr>
                                    <td class="pt-4">
                                        <span class="white-space-no-wrap">
                                            Le client s'est connecté
                                        </span>
                                    </td>

                                    <td class="pl-4 pt-4">
                                        <div class="d-flex align-center">
                                            <v-btn-toggle v-model="search.extranetConnected" @change="fetchSales()" class="mr-4" color="app-blue" dense>
                                                <v-btn value="1">
                                                    Oui
                                                </v-btn>

                                                <v-btn value="0">
                                                    Non
                                                </v-btn>
                                            </v-btn-toggle>

                                            <div>
                                                <DatePickerMenu v-model="search.extranetLastLoginDate" @input="fetchSales()" label="Dernière connexion" range />
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </table>

                            <v-divider v-if="displayAppointments" vertical />

                            <table v-if="displayAppointments">
                                <tr>
                                    <td>
                                        <span class="white-space-no-wrap mr-2">
                                            Rendez-vous lancés
                                        </span>
                                    </td>

                                    <td class="pl-4 text-right">
                                        <v-btn-toggle v-model="search.appointmentStarted" @change="fetchSales()" color="app-blue" dense>
                                            <v-btn value="1">
                                                Oui
                                            </v-btn>

                                            <v-btn value="0">
                                                Non
                                            </v-btn>
                                        </v-btn-toggle>
                                    </td>

                                    <td class="pl-4">
                                        <div class="d-flex align-center">
                                            <div>
                                                <DatePickerMenu v-model="search.appointmentStartedDate" @input="fetchSales()" label="Lancement RDV" range />
                                            </div>
                                        </div>
                                    </td>
                                </tr>

                                <tr>
                                    <td class="pt-4">
                                        <span class="white-space-no-wrap">
                                            Etat
                                        </span>
                                    </td>

                                    <td></td>

                                    <td class="pl-4 pt-4">
                                        <AppointmentStatusSelect v-model="search.appointmentStatus" @input="fetchSales()" multiple label="Etat" />
                                    </td>
                                </tr>
                            </table>
                        </div>

                        <v-divider v-if="displaySOT" class="my-3" />

                        <div v-if="displaySOT" class="d-flex justify-space-around">
                            <table>
                                <tr>
                                    <td>
                                        <span class="white-space-no-wrap">
                                            TMA Ouverts
                                        </span>
                                    </td>

                                    <td class="text-right">
                                        <v-btn-toggle color="app-blue" dense>
                                            <v-btn>
                                                Oui
                                            </v-btn>

                                            <v-btn>
                                                Non
                                            </v-btn>
                                        </v-btn-toggle>
                                    </td>

                                    <td class="pl-4">
                                        <div class="d-flex align-center">

                                            <div>
                                                <DatePickerMenu v-model="search.sruSentDate" @input="fetchSales()" label="Date de fin SRU" range />
                                            </div>
                                        </div>
                                    </td>
                                </tr>

                                <tr>
                                    <td class="pt-4">
                                        <span class="white-space-no-wrap">
                                            Avancement
                                        </span>
                                    </td>

                                    <td class="pt-4">
                                        <v-select hide-details outlined dense background-color="white" label="Avancement" />
                                    </td>

                                    <td class="pl-4 pt-4">
                                        <div class="d-flex align-center">

                                            <div>
                                                <DatePickerMenu v-model="search.sruSentDate" @input="fetchSales()" label="Date de fin SRU" range />
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </table>

                            <v-divider vertical />

                            <table>
                                <tr>
                                    <td>
                                        <span class="white-space-no-wrap">
                                            Choix & options ouverts
                                        </span>
                                    </td>

                                    <td class="text-right">
                                        <v-btn-toggle color="app-blue" dense>
                                            <v-btn>
                                                Oui
                                            </v-btn>

                                            <v-btn>
                                                Non
                                            </v-btn>
                                        </v-btn-toggle>
                                    </td>

                                    <td class="pl-4">
                                        <div class="d-flex align-center">

                                            <div>
                                                <DatePickerMenu v-model="search.sruSentDate" @input="fetchSales()" label="Date de fin SRU" range />
                                            </div>
                                        </div>
                                    </td>
                                </tr>

                                <tr>
                                    <td class="pt-4">
                                        <span class="white-space-no-wrap">
                                            Avancement
                                        </span>
                                    </td>

                                    <td class="pt-4">
                                        <v-select hide-details outlined dense background-color="white" label="Avancement" />
                                    </td>

                                    <td class="pl-4 pt-4">
                                        <div class="d-flex align-center">

                                            <div>
                                                <DatePickerMenu v-model="search.sruSentDate" @input="fetchSales()" label="Date de fin SRU" range />
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </v-sheet>
            </v-expand-transition>

            <v-divider class="mb-3" />

            <!-- Sales table -->
            <v-simple-table class="rounded elevation-2 mx-2">
                <template>
                    <thead>
                        <tr>
                            <th>
                                <SortArrows title="Acquéreur" v-model="orderBy" asc="buyer:asc" desc="buyer:desc" @input="fetchSales()" />
                                <SortArrows title="Dernière modification" v-model="orderBy" asc="lastEvent:asc" desc="lastEvent:desc" @input="fetchSales()" />
                            </th>

                            <th>
                                <SortArrows title="Programmes" v-model="orderBy" asc="program:asc" desc="program:desc" @input="fetchSales()" />
                                Lots
                            </th>

                            <th>
                                <SortArrows title="Date contrat" v-model="orderBy" asc="saleDate:asc" desc="saleDate:desc" @input="fetchSales()" />
                                <SortArrows title="Date fin SRU" v-model="orderBy" asc="sruSentDate:asc" desc="sruSentDate:desc" @input="fetchSales()" />
                            </th>

                            <th>
                                <SortArrows title="Espace ouvert ?" v-model="orderBy" asc="extranetOpenDate:asc" desc="extranetOpenDate:desc" @input="fetchSales()" />
                                <SortArrows title="Connecté ?" v-model="orderBy" asc="extranetLastLogin:asc" desc="extranetLastLogin:desc" @input="fetchSales()" />
                            </th>

                            <th v-if="displayAppointments">
                                <SortArrows title="Rendez-vous lancés ?" v-model="orderBy" asc="appointmentStarted:asc" desc="appointmentStarted:desc" @input="fetchSales()" />
                                <SortArrows title="Etat" v-model="orderBy" asc="appointmentState:asc" desc="appointmentState:desc" @input="fetchSales()" />
                            </th>

                            <th v-if="displaySOT">
                                <SortArrows title="TMA ouverts ?" v-model="orderBy" asc="tmaOpen:asc" desc="tmaOpen:desc" @input="fetchSales()" />
                                <SortArrows title="Avancement" v-model="orderBy" asc="tmaState:asc" desc="tmaState:desc" @input="fetchSales()" />
                            </th>

                            <th v-if="displaySOT">
                                <SortArrows title="Choix & options ouvertes" v-model="orderBy" asc="choicesAndOptions:asc" desc="choicesAndOptions:desc" @input="fetchSales()" />
                                <SortArrows title="Avancement" v-model="orderBy" asc="choicesAndOptionsState:asc" desc="choicesAndOptionsState:desc" @input="fetchSales()" />
                            </th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr v-for="sale of sales" :key="sale.id">
                            <!-- Acquéreur - Dernier événement  -->
                            <td>
                                <span v-for="(buyer, i) of sale.buyers" :key="buyer.id + '_' + buyer.type"> 
                                    {{ buyer.name }} {{ buyer.firstname }}
                                    <template v-if="i < sale.buyers.length - 1"> / </template>
                                </span>
                                <br>
                                <template v-if="sale.modified">
                                    {{ sale.modified | toDate() }}
                                </template>
                            </td>

                            <!-- Programmes - Lots -->
                            <td>
                                {{ sale.program.title }} <br>
                                {{ sale.product.reference }} {{ sale.product.typeLabel }} {{ sale.product.roomsLabel }}
                            </td>

                            <!-- Date contrat - Date fin SRU -->
                            <td>
                                {{ sale.date | toDate() }} <br>
                                {{ sale.sruSentDate | toDate() }}
                            </td>

                            <!-- Espace ouvert ? - Connecté ? -->
                            <td>
                                <v-tooltip v-if="sale.extranet.open" top>
                                    <template v-slot:activator="{ on }">
                                        <v-chip class="my-1 mr-2" color="green lighten-2" small label dark v-on="on"> Ouvert </v-chip>
                                    </template>
                                    Accès ouvert le {{ sale.extranet.openDate | toDate() }} 
                                    <template v-if="sale.extranet.lastSignUpNotificationDate">
                                        <br>
                                        Dernier envoi le {{ sale.extranet.lastSignUpNotificationDate | toDate() }} <br>
                                        {{ sale.extranet.signUpNotificationCount }} {{ sale.extranet.signUpNotificationCount | plural('envoi', 'envois') }} au total
                                    </template>
                                </v-tooltip>

                                <ResendSignupNotification :sale="sale" @signUpSent="fetchSales()">
                                    <template v-slot:activator="{ on: tooltip }">
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on: dialog }">
                                                <v-btn icon v-on="{ ...dialog, ...tooltip }">
                                                    <v-icon>mdi-send</v-icon>
                                                </v-btn>
                                            </template>
                                            Renvoyer le mail de connexion
                                        </v-tooltip>
                                    </template>
                                </ResendSignupNotification>

                                <br>

                                <v-tooltip v-if="sale.extranet.connected" top>
                                    <template v-slot:activator="{ on }">
                                        <v-chip class="my-1" color="green lighten-2" small label dark v-on="on"> Connecté </v-chip>
                                    </template>
                                    Première connexion le {{ sale.extranet.firstLoginDate | toDate() }} <br>
                                    Dernière connexion le {{ sale.extranet.lastLoginDate | toDate() }} <br>
                                    {{ sale.extranet.connectionsCount }} {{ sale.extranet.connectionsCount | plural('connexion', 'connexions') }} au total
                                </v-tooltip>
                                <v-chip v-else class="my-1" color="red lighten-2" small label dark> Non connecté </v-chip>
                            </td>

                            <!-- Rendez-vous lancés ? - Etat -->
                            <td v-if="displayAppointments">
                                <ManageBuyerAppointmentDialog :sale="sale">
                                    <template v-slot:activator="{ on: dialog }">
                                        <v-tooltip v-if="sale.appointmentStarted" top>
                                            <template v-slot:activator="{ on: tooltip }">
                                                <v-chip class="my-1" color="green lighten-2" small label dark v-on="{ ...dialog, ...tooltip }"> Lancé </v-chip>
                                            </template>
                                            Premier rendez-vous lancé le {{ sale.appointmentStartedDate | toDate() }}
                                        </v-tooltip>
                                        <v-chip v-else class="my-1" color="red lighten-2" small label dark v-on="{ ...dialog }"> Pas lancé </v-chip>
                                    </template>
                                </ManageBuyerAppointmentDialog>

                                <br>

                                <template v-if="sale.appointment.id">
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on: tooltip }">
                                            <v-chip class="my-1" :color="getAppointmentStatusColor(sale.appointment.status)" small label dark v-on="{ ...tooltip }"> {{ getAppointmentStatusLabel(sale.appointment.status) }} </v-chip>
                                        </template>

                                        <template v-if="sale.appointment.status !== 0">
                                            {{ sale.appointment.subject }} à programmer avant le {{ sale.appointment.maxDate | toDate() }}
                                        </template>
                                        <template v-else>
                                            {{ sale.appointment.subject }} à venir le {{ sale.appointment.startDate | toDatetime() }}
                                        </template>
                                    </v-tooltip>
                                </template>
                            </td>

                            <!-- TMA ouverts ? - Avancement -->
                            <td v-if="displaySOT">
                                <v-chip class="my-1" color="red lighten-2" small label dark> Fermé </v-chip>
                            </td>

                            <!-- Choix & options ouvertes - Avancement -->
                            <td v-if="displaySOT">
                                <v-chip class="my-1" color="red lighten-2" small label dark> Fermé </v-chip>
                            </td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>

            <v-divider class="mt-3" />

            <!-- Pagination -->
            <v-card-title class="d-flex align-center px-2 mt-2 pb-0">
                <v-spacer />

                <v-pagination v-model="page" @input="fetchSales(true, false)" :length="salesTotalPages" total-visible="5" color="app-blue" />
            </v-card-title>
        </v-card>
    </div>
</template>


<script>
import appointmentsMixin from '../../mixins/appointments';

import SortArrows from '../../components/widgets/SortArrows.vue';
import DatePickerMenu from '../../components/widgets/DatePickerMenu.vue';
import ProgramAutocomplete from '../../components/widgets/ProgramAutocomplete.vue';
import AppointmentStatusSelect from '../../components/widgets/AppointmentStatusSelect.vue';
import CreateAppointmentDialog from '../../components/appointments/CreateAppointmentDialog.vue';
import ResendSignupNotification from '../../components/admin/sales/ResendSignupNotification.vue';
import ManageBuyerAppointmentDialog from '../../components/appointments/ManageBuyerAppointmentDialog.vue';


export default {
    name: 'SOTSales',

    mixins: [appointmentsMixin],

    components: {
        SortArrows,
        DatePickerMenu,
        ProgramAutocomplete,
        AppointmentStatusSelect,
        CreateAppointmentDialog,
        ResendSignupNotification,
        ManageBuyerAppointmentDialog
    },

    props: {
        displaySOT: { type: Boolean, default: false },
        displayAppointments: { type: Boolean, default: false }
    },

    data: () => ({
        page: 1,
        limit: 30,
        salesTotalPages: 0,
        salesTotalCount: 0,

        sales: [],

        displayAdvanceSearch: false,
        advanceSearchDataFecthed: false,
        search: {
            quickSearch: null,
            programs: null,
            contractDate: null,
            sruSentDate: null,
            extranetOpen: null,
            extranetConnected: null,
            appointmentStarted: null,
            extranetOpenDate: null,
            extranetLastLoginDate: null,
            appointmentStartedDate: null,
            appointmentStatus: null
        },

        orderBy: 'lastEvent:desc',
        defaultOrderBy: 'lastEvent:desc',
        sortList: {
            'buyer:asc': [{ field: 'buyer.name', order: 'asc' }, { field: 'buyer.firstname', order: 'asc' }],
            'buyer:desc': [{ field: 'buyer.name', order: 'desc' }, { field: 'buyer.firstname', order: 'desc' }],
            'lastEvent:asc': [{ field: 'modified', order: 'asc' }],
            'lastEvent:desc': [{ field: 'modified', order: 'desc' }],
            'program:asc': [{ field: 'program.title', order: 'asc' }],
            'program:desc': [{ field: 'program.title', order: 'desc' }],
            'saleDate:asc': [{ field: 'date', order: 'asc' }],
            'saleDate:desc': [{ field: 'date', order: 'desc' }],
            'sruSentDate:asc': [{ field: 'sruSentDate', order: 'asc' }],
            'sruSentDate:desc': [{ field: 'sruSentDate', order: 'desc' }],
            'extranetOpenDate:asc': [{ field: 'extranet.openDate', order: 'asc' }],
            'extranetOpenDate:desc': [{ field: 'extranet.openDate', order: 'desc' }],
            'extranetLastLogin:asc': [{ field: 'extranet.lastLoginDate', order: 'asc' }],
            'extranetLastLogin:desc': [{ field: 'extranet.lastLoginDate', order: 'desc' }],
            'appointmentStarted:asc': [{ field: 'appointmentStarted', order: 'asc' }],
            'appointmentStarted:desc': [{ field: 'appointmentStarted', order: 'desc' }]
        }
    }),

    watch: {
        displayAdvanceSearch() {
            if (!this.advanceSearchDataFecthed) {
                this.advanceSearchDataFecthed = true;
            }
        }
    },

    methods: {
        doSearch() {
            clearTimeout(this.searchTimeout);
            this.searchTimeout = setTimeout(() => {
                this.fetchSales();
            }, 500);
        },

        async fetchSales(push = true, resetPage = true) {
            try {
                this.setLoading(true);

                if (resetPage) {
                    this.page = 1;
                }

                if (push && this.doPush(this.displayQuery)) {
                    this.$router.push({ query: this.displayQuery });
                }

                const { sales, count, totalPages, err } = await this.repos.sales.getSales(this.query);
                if (err) {
                    throw new Error(err);
                }
                this.sales = sales;
                this.salesTotalCount = count;
                this.salesTotalPages = totalPages;
            } catch (err) {
                console.error(err);
                this.$notify({
                    title: 'Erreur',
                    text: 'Une erreur est survenue lors du chargement des réservations',
                    type: 'error'
                });
            } finally {
                this.setLoading(false);
            }
        },

        parseQuery(query) {
            this.page = parseInt(query.page) || 1;
            this.orderBy = query.orderBy || this.defaultOrderBy;
            this.search.quickSearch = query.quickSearch || null;
            this.search.programs = query.programs ? query.programs.split(',').map((p) => parseInt(p)) : [];
            this.search.contractDate = query.contractDate ? query.contractDate.split(',') : [];
            this.search.sruSentDate = query.sruSentDate ? query.sruSentDate.split(',') : [];
            this.search.extranetOpenDate = query.extranetOpenDate ? query.extranetOpenDate.split(',') : [];
            this.search.extranetLastLoginDate = query.extranetLastLoginDate ? query.extranetLastLoginDate.split(',') : [];
            this.search.appointmentStartedDate = query.appointmentStartedDate ? query.appointmentStartedDate.split(',') : [];
            this.search.appointmentStatus = query.appointmentStatus ? query.appointmentStatus.split(',').map((p) => p === '-1' ? null : parseInt(p)) : [];
            this.search.extranetOpen = typeof query.extranetOpen === 'string' ? query.extranetOpen : undefined;
            this.search.extranetConnected = typeof query.extranetConnected === 'string' ? query.extranetConnected : undefined;
            this.search.appointmentStarted = typeof query.appointmentStarted === 'string' ? query.appointmentStarted : undefined;
        },

        doPush(query) {
            return (Object.keys(query).length !== Object.keys(this.$route.query).length) || !Object.keys(query).every((key) => query[key] === this.$route.query[key]);
        }
    },

    computed: {
        query() {
            const orderBy = this.sortList[this.orderBy].map((s) => `${s.field}:${s.order}`).join(',');
            const query = {
                page: this.page,
                limit: this.limit,
                include: 'buyers,appointments',
                orderBy
            };

            if (this.search.quickSearch) {
                query.quickSearch = this.search.quickSearch;
            }

            if (this.search.programs) {
                query.programs = this.search.programs;
            }

            if (Array.isArray(this.search.contractDate) && this.search.contractDate.length > 0) {
                query.contractDate = this.search.contractDate.join(',');
            }

            if (Array.isArray(this.search.sruSentDate) && this.search.sruSentDate.length > 0) {
                query.sruSentDate = this.search.sruSentDate.join(',');
            }

            if (Array.isArray(this.search.extranetOpenDate) && this.search.extranetOpenDate.length > 0) {
                query.extranetOpenDate = this.search.extranetOpenDate.join(',');
            }

            if (Array.isArray(this.search.extranetLastLoginDate) && this.search.extranetLastLoginDate.length > 0) {
                query.extranetLastLoginDate = this.search.extranetLastLoginDate.join(',');
            }

            if (Array.isArray(this.search.appointmentStartedDate) && this.search.appointmentStartedDate.length > 0) {
                query.appointmentStartedDate = this.search.appointmentStartedDate.join(',');
            }

            if (Array.isArray(this.search.appointmentStatus) && this.search.appointmentStatus.length > 0) {
                const statuses = this.search.appointmentStatus.map((s) => s === null ? -1 : s);
                query.appointmentStatus = statuses.join(',');
            }

            if (this.search.extranetOpen) {
                query.extranetOpen = this.search.extranetOpen;
            }

            if (this.search.extranetConnected) {
                query.extranetConnected = this.search.extranetConnected;
            }

            if (this.search.appointmentStarted) {
                query.appointmentStarted = this.search.appointmentStarted;
            }

            return query;
        },

        displayQuery() {
            const query = JSON.parse(JSON.stringify(this.query));
            delete query.limit;
            delete query.include;
            delete query.debug;

            if (query.page === 1) {
                delete query.page;
            }

            delete query.orderBy;
            if (this.orderBy !== this.defaultOrderBy) {
                query.orderBy = this.orderBy;
            }

            return query;
        }
    },

    created() {
        this.parseQuery(this.$route.query);
        this.fetchSales(false, false);
        this.$nextTick(() => {
            this.$refs.programAutocomplete.fetchPrograms({ limit: 1000 });
        });
    }
};
</script>